<template>
  <div v-if="item" class="item grid ggap-20 p-20 pos-r box">
    <!-- Delete confirmation -->
    <div v-if="isDelete" class="item__delete pos-a z2 flex fd-c ai-c jc-c ggap-10">
      <div v-if="isLoad" class="flex fd-c ai-c ggap-5">
        <BaseLoad class="rel sm" />
        <h3 class="title t-white">Идет удаление записи!</h3>
      </div>
      <template v-else>
        <h3 class="title t-white">Вы действительно хотите удалить мероприятие?</h3>
        <div class="flex ai-c ggap-10">
          <button class="btn primary sm" @click.stop="deleteAction(true)">Удалить</button>
          <button class="btn cancel sm" @click.stop="isDelete = false">Отмена</button>
        </div>
      </template>
    </div>

    <!-- Data -->
    <div class="flex ggap-10 jc-sb ai-fs" :class="[isMobile ? 'fd-c ai-c' : 'gtc-auto-1']">
      <div class="grid ggap-20" :class="[isMobile ? 'gtc-1 w-100' : 'gtc-auto-1']">
        <div class="item__image cover" @click.stop="openMedia">
          <img :src="mainImage" />
          <small class="marker grey" v-tippy="'Всего изображений: 2'">2</small>
        </div>
        <div class="grid ai-fs pr-20 ggap-5">
          <!-- Title -->
          <div class="item__title title cut fs-18" :title="item.name">{{ item.name }}</div>

          <!-- Links -->
          <div class="item__sub flex fw ai-c">
            <span class="flex ai-c ggap-5">
              <BaseIcon class="ic-14 grey" icon="location" />
              {{ item.location }}
            </span>
          </div>
          <div class="item__sub flex fw ai-c ggap-5 mb-10">
            <span class="flex ai-c ggap-5">
              <BaseIcon class="ic-14 grey" icon="users" />
              {{ item.capacity }} - {{ item.max_capacity }} человек
            </span>
            <span class="flex ai-c ggap-5">
              <BaseIcon class="ic-14 grey" icon="calendar" />
              {{ date || moment().format('YYYY-MM-DD') }}
            </span>
          </div>
          <!-- Tags -->
          <div class="item__tags flex fw ggap-10">
            <span v-for="tag of item.tags" :key="tag.id" class="tag-item user-select-none">{{ tag.name }}</span>
          </div>
        </div>
      </div>
      <div class="flex ggap-10 ai-c" :class="{ 'jc-c flex w-100': isMobile }">
        <button
          class="btn primary"
          :class="{ 'jc-c flex w-100': isMobile }"
          @click="
            $busEmit('edit', {
              url: 'meeting-rooms/booking.vue',
              id: { meetingRoomId: item.id, hour: moment().format('HH') }
            })
          "
        >
          Забронировать
        </button>
        <!-- Menu -->
        <BaseDotMenu v-if="!isDelete && isAdmin">
          <button
            class="btn transparent-grey"
            @click.stop="$busEmit('view', { url: 'meeting-rooms/Index.vue', id: item.id })"
          >
            <BaseIcon class="ic-16 grey" icon="edit" /> Редактировать
          </button>
          <button class="btn transparent-grey" @click.stop="isDelete = !isDelete">
            <BaseIcon class="ic-16 grey" icon="delete" /> Удалить
          </button>
        </BaseDotMenu>
      </div>
    </div>

    <!-- Timeline -->
    <div class="flex ai-c" v-if="!isMobile">
      <ItemTimeline :item="item" />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineProps, ref, toRefs, computed } from 'vue'
import { BaseLoad, BaseIcon, BaseDotMenu } from '@/components'
import { $busEmit } from '@/plugins'
import ItemTimeline from './ItemTimeline.vue'
import moment from 'moment'

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  timeline: {
    type: Array,
    default: () => []
  }
})

// Data
const { item } = toRefs(props)
const store = useStore()
const isDelete = ref(false)
const isLoad = ref(false)

// Computed
const profile = computed(() => store.getters.profile)
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')
const isMobile = computed(() => store.getters.isMobile)
const mainImage = computed(() => item.value?.image || require('@/assets/img/no-photo.jpg'))
const date = computed(() => store.getters['meetingRooms/date'])

// Methods
async function deleteAction() {
  try {
    isLoad.value = true
    await store.dispatch('meetingRooms/DELETE', item.value.id)
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: `Переговорная успешно удалена!`
    })
    store.dispatch('helpers/GET_USER_EVENTS')
  } finally {
    isLoad.value = false
  }
}
function openMedia() {
  const selected = { id: 1, url: item.value?.image, type: 'image' }
  const arr = [selected, { id: 2, url: item.value?.secondary_image, type: 'image' }]
  $busEmit('openMedia', { selected, arr })
}
</script>

<style lang="scss" scoped>
.item {
  &:hover {
    // background-color: var(--grey-el);
    z-index: 3;
  }

  &__image {
    width: 150px;
    height: 100px;
    border-radius: var(--br);
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #000;

      img {
        opacity: 0.8;
      }
    }

    .marker {
      position: absolute;
      right: 4px;
      bottom: 4px;
      z-index: 1;
    }
  }

  &__delete {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: var(--rgba-red-08);
    backdrop-filter: blur(5px);
  }

  &__title {
    color: var(--text);
    font-weight: bold;
  }

  &__sub {
    span,
    a {
      display: inline-flex;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  &__timeline {
    grid-gap: 1px;
  }
}

@media screen and (max-width: 800px) {
  .item {
    &__image {
      width: 100%;
      height: 150px;
    }
  }
}
</style>
