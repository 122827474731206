<template>
  <RouterView />
  <div class="meeting-rooms flex fd-c ggap-10 pos-r">
    <!-- Toolbar -->
    <div class="meeting-rooms__toolbar box">
      <div class="meeting-rooms__toolbar-filter pt-10 pb-10 pl-20 pr-20 grid ggap-20">
        <div class="filters grid" v-if="isFilters">
          <div class="grid ggap-10" :class="[isMobile ? 'gtc-1' : 'gtc-2']">
            <FormDate type="date" clearable="true" format="YYYY-MM-DD" label="Выберите дату" v-model="formData.date" />
            <FormSelect label="Вместимость" class="sm white" :options="capacityOptions" v-model="formData.capacity" />
          </div>
          <small class="form-item__label mt-20" v-if="tags.length">Выберите теги</small>
          <div class="horizontal-scroll pointer" v-if="tags.length">
            <span
              v-for="tag of tags"
              :key="tag.id"
              :class="{ 'active-tag': formData.selectedTags.includes(tag.id) }"
              class="tag-item"
              @click="addTagToFilter(tag.id)"
              >{{ tag.name }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="pos-r z1">
      <BaseLoad v-if="isLoad" class="grid jc-c z3 bg white box mh-100" />
      <template v-else>
        <template v-if="rows && rows.length">
          <div class="meeting-rooms__list grid ggap-10 mb-10">
            <Item v-for="item of rows" :key="item.id + '_' + updateComponent" :item="item" @updateList="getRows" />
          </div>
          <BaseLoad v-if="isLoadMore" class="rel sm" />
        </template>
        <div v-else class="p-20 ta-center box">
          <h2 class="title mb-5">Упс, ничего не найдено!</h2>
          <small class="t-grey-ed">Попробуйте искать иначе либо в другом месте.</small>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { debounce } from 'lodash'
import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue'
import { BaseLoad, FormDate, FormSelect } from '@/components'
import { $busOn, socketConnect } from '@/plugins'
import Item from './components/Item.vue'

// Computed
const store = useStore()
const rows = computed(() => store.getters['meetingRooms/rows'])
const tags = computed(() => store.getters['helpers/meetingRoomsTags'])
const date = computed(() => store.getters['meetingRooms/date'])
const text = computed(() => store.getters['meetingRooms/searchText'])
const capacity = computed(() => store.getters['meetingRooms/capacity'])
const selectedTags = computed(() => store.getters['meetingRooms/selectedTags'])
store.dispatch('helpers/GET_MEETING_ROOMS_TAGS')

const capacityOptions = [
  { text: 2, value: 2 },
  { text: 5, value: 5 },
  { text: 10, value: 10 },
  { text: 15, value: 15 },
  { text: 20, value: 20 },
  { text: 25, value: 25 },
  { text: 30, value: 30 },
  { text: 35, value: 35 },
  { text: 40, value: 40 }
]

// Data
const isLoad = ref(false)
const isLoadMore = ref(false)
const currentPage = ref(1)
const searchText = ref(text.value || '')
const updateComponent = ref(0)
const isFilters = ref(true)
const isMobile = computed(() => store.getters.isMobile)
const formData = ref({
  date: date.value,
  capacity: capacity.value,
  selectedTags: selectedTags.value || []
})

// Created
store.dispatch('meetingRooms/GET_ROWS')
getRows()
wsConnect()
$busOn('updateBooking', () => {
  updateComponent.value += 1
})

// Watch
watch(formData.value, () => getRows())
watch(searchText, debounce(getRows, 500))
watch(currentPage, () => {
  store.commit('meetingRooms/SET_CURRENT_PAGE', currentPage.value)
  getRows()
})

function addTagToFilter(id) {
  if (formData.value?.selectedTags.includes(id)) {
    formData.value.selectedTags = formData.value.selectedTags.filter((i) => i != id)
  } else {
    formData.value.selectedTags = [...formData.value.selectedTags, id]
  }
}
// Methods
async function getRows(update = true) {
  if (update) isLoad.value = true
  store.commit('meetingRooms/SET_SEARCH_TEXT', searchText)
  store.commit('meetingRooms/SET_SEARCH_DATE', formData.value.date)
  store.commit('meetingRooms/SET_SEARCH_CAPACITY', formData.value.capacity)
  store.commit('meetingRooms/SET_SEARCH_TAGS', formData.value.selectedTags)
  await store.dispatch('meetingRooms/GET_ROWS')
  await store.dispatch('helpers/GET_USER_EVENTS')
  isLoad.value = false
}

function wsConnect() {
  const name = `user.booking`
  socketConnect.channel(name).listen('.NegotiationBookingBroadcast', (data) => {
    if (data?.message) {
      getRows(false)
    }
  })
}
</script>

<style lang="scss" scoped>
.meeting-rooms {
  &__toolbar {
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 -30px 0 0 var(--bg);

    &-head {
      height: 50px;
    }

    &-search {
      .search,
      .btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .search {
        left: 10px;
        pointer-events: none;
      }
      .btn {
        right: 5px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .meeting-rooms {
    &__toolbar {
      position: static;
      box-shadow: 0 -30px 0 0 var(--bg);
    }
  }
}
</style>
