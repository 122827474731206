<template>
  <div
    :style="{ width: item.width + '%', marginLeft: item.left + '%' }"
    v-tippy="information"
    class="booking-item"
    :class="item.owner.id === profile.id ? 'marker primary' : 'marker primary-dark'"
  ></div>
</template>

<script setup>
import { defineProps, toRefs, computed } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})

const { item } = toRefs(props)
const store = useStore()
const profile = computed(() => store.getters.profile)
const information = `
  Название: ${item.value?.name} <br />
  Бронировал: ${item.value?.owner?.fullname} <br />
  Время: ${moment(item.value?.date_start).format('HH:mm')} -
  ${moment(item.value?.date_end).format('HH:mm')} <br />
  ${'Участников: ' + (item.value?.user?.length + 1)}
`
</script>

<style lang="scss" scoped>
.booking-item {
  height: 16px;
  position: absolute;
  border-radius: 50px;
  top: 0;
  padding: 0;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
}
</style>
