<template>
  <span class="item__timeline-section ta-center grid pointer">
    <div class="item__timeline-section-status">
      <div
        v-for="(h, index) in anotherHours"
        :key="index"
        class="invisible-item"
        @click.stop="getInformation({}, h)"
      ></div>
      <TimelineBlock
        @click.stop="getInformation(book)"
        v-for="book in booking"
        :key="book.id + book.date_start + book.date_end"
        :item="book"
      />
    </div>
    <div class="times">
      <small v-for="hour in hours" :key="hour"> {{ hour }}:00 </small>
    </div>
  </span>
</template>

<script setup>
import { defineProps, toRefs, computed } from 'vue'
import { $busEmit } from '@/plugins'
import moment from 'moment'
import TimelineBlock from './TimelineBlock.vue'
import { useStore } from 'vuex'

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})
// Data
const { item } = toRefs(props)
const store = useStore()
const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
const anotherHours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
const booking = item.value?.booking.map((item) => {
  const start = moment(item?.date_start, 'YYYY-MM-DD HH:mm:ss')
  const end = moment(item?.date_end, 'YYYY-MM-DD HH:mm:ss')
  const eight = new Date(moment(item?.date_start).toISOString())
  eight.setHours(8)
  eight.setMinutes(0)
  eight.setSeconds(0)
  const duration = end.diff(start, 'minutes')
  const startNumber = new Date(moment(item?.date_start).toISOString())
  const diffMinutes = Math.ceil((startNumber.getTime() - eight.getTime()) / (1000 * 60))
  return {
    ...item,
    width: (100 / 24) * (duration / 30),
    left: (100 / 24) * (diffMinutes / 30)
  }
})

// Computed
const profile = computed(() => store.getters.profile)
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')
const date = computed(() => store.getters['meetingRooms/date'])
// Functions
function getInformation(book, h) {
  const isToday = moment().format('YYYY-MM-DD') === moment(book?.date_start).format('YYYY-MM-DD')
  const isPastReserve = Number(moment().format('HH')) < Number(moment(book?.date_end).format('HH'))
  if (h) {
    const time = h === 20 ? 19 : h
    let hour =
      moment().format('HH') > time && moment().format('YYYY-MM-DD') && isToday === date.value
        ? moment().format('HH')
        : time
    $busEmit('edit', { url: 'meeting-rooms/booking.vue', id: { booking: book, meetingRoomId: item.value?.id, hour } })
  } else if ((isToday && isPastReserve) || !isToday || isAdmin.value) {
    if (book?.owner?.id === profile.value.id || isAdmin.value) {
      $busEmit('edit', { url: 'meeting-rooms/booking.vue', id: { booking: book, meetingRoomId: item.value?.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.invisible-item {
  width: calc(100% / 12);
  height: 100%;
  border-radius: 50px;
}
.invisible-item:hover {
  background: var(--selected);
}
.times {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.item__timeline-section {
  flex: 1;
  $section: &;

  &-status {
    height: 16px;
    background-color: var(--grey-d);
    position: relative;
    border-radius: 50px;
    display: flex;
  }

  &-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    opacity: 0;
  }

  &:first-child &-btn {
    opacity: 1;
  }
  &:last-child &-btn {
    opacity: 1;
  }

  &.blue &-status {
    background-color: var(--primary);
  }
  &.red &-status {
    background-color: var(--red);
  }
  &.blue &-btn {
    opacity: 1;
  }
  &.red &-btn {
    opacity: 1;
  }

  &:hover {
    #{$section}-status {
      z-index: 1;
      box-shadow: 0 0 0 2px var(--box);
    }
    #{$section}-btn {
      opacity: 1;
    }
  }

  &:hover &-btn {
    color: var(--text);
  }
}
</style>
